import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MenuManager from './components/MenuManager';
import Login from './components/Login';
import AppMainPage from './components/AppMainPage';
import './App.css';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if (isLoggedIn) {
            // Clear any existing timer
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            // Set a timer for 10 minutes
            const id = setTimeout(() => {
                setIsLoggedIn(false);
                alert('Session expired. Please log in again.');
            }, 600000); // 600000ms = 10 minutes
            setTimeoutId(id);
        }
    }, [isLoggedIn, timeoutId]);

    const handleLogout = () => {
        setIsLoggedIn(false);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    };

    return (
        <Router>
            <div className="app">
                <Routes>
                    <Route path="/login" element={<Login onLogin={setIsLoggedIn} />} />
                    <Route path="/menu" element={isLoggedIn ? <MenuManager onLogout={handleLogout} /> : <Navigate to="/login" />} />
                    <Route path="/" element={<Navigate to="/login" />} />
                    {/* <Route path="/" element={<AppMainPage />} /> */}
                    {/* <Route path="/appmainpage" element={<AppMainPage />} /> */}
                </Routes>
            </div>
        </Router>
    );
};

export default App;
