import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MenuManager.css';

const MenuManager = ({ onLogout }) => {
    useEffect(() => {
        document.title = 'Menu - Speedex App';
    }, []);
    const [menu, setMenu] = useState([]);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        fetchMenu();
    }, []);

    const fetchMenu = async () => {
        try {
            const response = await axios.get('https://menu.speedex.info/api/menu');
            setMenu(response.data);
        } catch (error) {
            console.error('Error fetching menu:', error);
        }
    };

    const handleAddOrEdit = async () => {
        if (name === '' || url === '') {
            alert('Please fill out all fields.');
            return;
        }

        const item = { name, url };

        try {
            if (editId) {
                // Edit existing menu item
                await axios.put(`https://menu.speedex.info/api/menu/${editId}`, item);
                alert('Menu item updated successfully!');
            } else {
                // Add new menu item
                await axios.post('https://menu.speedex.info/api/menu', item);
                alert('Menu item added successfully!');
            }
            // Reset form fields
            setName('');
            setUrl('');
            setEditId(null);
            fetchMenu();
        } catch (error) {
            console.error('Error saving menu item:', error);
        }
    };

    const handleEdit = (item) => {
        setName(item.name);
        setUrl(item.url);
        setEditId(item.id);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://menu.speedex.info/api/menu/${id}`);
            alert('Menu item deleted successfully!');
            fetchMenu();
        } catch (error) {
            console.error('Error deleting menu item:', error);
        }
    };

    return (
        <div className="menu-manager">
            <h1>Menu Manager</h1>
            <div className="form-container">
                <input 
                    type="text" 
                    placeholder="Name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    className="input-field"
                />
                <input 
                    type="text" 
                    placeholder="Url" 
                    value={url} 
                    onChange={(e) => setUrl(e.target.value)} 
                    className="input-field"
                />
                <button onClick={handleAddOrEdit} className="submit-button">{editId ? 'Edit' : 'Add'}</button>
            </div>
            <ul className="menu-list">
                {menu.map(item => (
                    <li key={item.id} className="menu-item">
                        <span>{item.name} - {item.url}</span>
                        <div className="button-container">
                            <button onClick={() => handleEdit(item)} className="edit-button">Edit</button>
                            <button onClick={() => handleDelete(item.id)} className="delete-button">Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
            <button onClick={onLogout} className="logout-button">Logout</button>
        </div>
    );
};

export default MenuManager;
